import validateForms from '../form-validation';
import { quantitySelectorHandler } from '../components/quantity-selector';
import { productLineItemsHandler } from "../components/product-line-items-handler"

/**
 * Cart Handler (Public/Exported)
 *
 * This is the entry point method for the CartController based views that gets called in main.js's `ready()` method.
 */
export const cartOnReady = () => {
    // Final State Method Calls
    validateForms();
    quantitySelectorHandler();
    productLineItemsHandler();

    // Methods yet to possibly generalized...
    cartCustomerPOItemCheckboxHandler();
    unsavedFormUpdatesAlertHandler();
    validateQuantityInputsHandler();
}

/* =====================================================================================================================
    CART'S "ARE YOU SURE YOU WANT TO LEAVE?" ALERT MESSAGE METHODS
===================================================================================================================== */
const cartViewSessionStorage = window.sessionStorage;

/**
 * Unsaved Form Updates Alert Handler
 *
 * This method calls all related methods to handle the presentation of a browser alert message if a user tries to
 * navigate away from the Cart show page after updating any form input. In other words, it warns the user that they have
 * unsaved Cart changes.
 *
 * @return {void} Calls related alert handler methods and initializes cartViewSessionStorage data with initial form data
 *                on initial page load.
 */
const unsavedFormUpdatesAlertHandler = () => {
    // Initialize session storage data fields with current form data...
    storeOriginalCartFormData(serializeCurrentCartFormData());
    storePreventAlertBool(false);

    preventAlertMessageEventListener();
    presentAlertMessageEventListener();
}

/**
 * Prevent Alert Message Event Listener
 *
 * This is an "on click" event listener put directly on the "Update Cart" and "Submit Order" buttons present via the
 * Cart show page. If either of these buttons are clicked on by the user, we add a special boolean flag to the
 * cartViewSessionStorage object to let us know that we dont want to present an Alert message about unsaved data.
 *
 * @return {void} Add true flag to cartViewSessionStorage about skipping Alert message on update/submit button click.
 */
const preventAlertMessageEventListener = () => {
    const cartSubmissionButtons = document.querySelectorAll('.js-cart-submission');

    if (cartSubmissionButtons.length > 0) {
        cartSubmissionButtons.forEach((submissionButton) => {
            submissionButton.addEventListener('click', (_) => {
                storePreventAlertBool(true);
            });
        });
    }
}

/**
 * Present Alert Message Event Listener
 *
 * This method uses the browser's "onbeforeunload" web hook to trigger a default "are you sure you want to leave this
 * page?" message if there is still unsaved data in the Cart's form. We won't present an Alert IF AND ONLY IF our
 * prevent alert session storage boolean was set too true OR the form data hasn't changed.
 *
 * Doc: https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload
 * Example Usage: https://stackoverflow.com/questions/147636/best-way-to-detect-when-a-user-leaves-a-web-page#answer-5893539
 *
 * @return {void} Presents an "Are you sure" browser message depending on different conditions.
 */
const presentAlertMessageEventListener = () => {
    window.onbeforeunload = (event) => {
        // Don't present alert message if update or submit buttons were clicked...
        if (getPreventAlertBool()) {
            cartViewSessionStorage.clear();
            return
        }

        // Don't present alert message id form data hasn't changed...
        if(isSameString(getOriginalCartFormData(), serializeCurrentCartFormData())) {
            cartViewSessionStorage.clear();
            return
        }

        // Default browser logic to present "Are you sure you want to leave" alert message...
        event.preventDefault();
        event.returnValue = '';
    }
}

/**
 * Serialize Current Cart Form Data
 *
 * This method will get the current dom instance of the Cart show page's form element and then return all of its data
 * serialized into a string.
 *
 * @return {String} String representation of all of the form data inputs.
 */
const serializeCurrentCartFormData = () => {
    const cartForm = document.querySelector('.js-cart-form');
    return serializeFormData(cartForm);
}

/**
 * Store Original Cart Form Data
 *
 * This session storage key should hold the initial serialized form data on initial page load. This way, we can compare
 * the initial payload against a later one captured when a user tries to navigate away from the Cart page.
 *
 * @param  {String} data Serialized form data to be saved to our cart view session storage object.
 * @return {void}        Serialized form data is saved to cartViewSessionStorage object via originalCartFormData key.
 */
const storeOriginalCartFormData = (data) => {
    cartViewSessionStorage.setItem('originalCartFormData', data);
}

/**
 * Get Original Cart Form Data
 *
 * This method will pull our the original serialized form data from our cartViewSessionStorage object by the correct
 * parameter key.
 *
 * @return {String} Original serialized form data that should have been captured on initial page load.
 */
const getOriginalCartFormData = () => {
    return cartViewSessionStorage.getItem('originalCartFormData');
}

/**
 * Store Prevent Alert Bool
 *
 * This method will save the passed in boolean value into the cartViewSessionStore object by the correct parameter key.
 * Notice that we have to store the boolean value as a string in our SessionStorage object since it only allows data to
 * be stored as strings.
 *
 * @param  {Boolean} data True/False boolean value of whether or not we want to skip "Are you sure?" alert message.
 * @return {void}         Store passed in boolean value as a string via cartViewSessionStorage object.
 */
const storePreventAlertBool = (data) => {
    if(typeof(data) !== 'boolean') return;

    cartViewSessionStorage.setItem('preventAlertBool', data === true ? 'true' : 'false');
}

/**
 * Get Prevent Alert Bool
 *
 * Get prevent alert boolean value out of the cartViewSessionStorage using the correct parameter key. We will also
 * convert the stored string 'true' or 'false' values into true boolean values.
 *
 * @return {Boolean} true or false boolean value of whether or not we want to skip "Are you sure?" alert message.
 */
const getPreventAlertBool = () => {
    const preventAlertBool = cartViewSessionStorage.getItem('preventAlertBool')

    if(!preventAlertBool) return false;

    return preventAlertBool === 'true';
}

/**
 * Is Same String?
 *
 * This method will compare to strings and return a boolean value to tell us if they are the same or not.
 *
 * Doc: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/localeCompare
 *
 * @param  {String}  stringOne First string we are comparing.
 * @param  {String}  stringTwo Second string we are comparing.
 * @return {Boolean}           Boolean value of whether or not these two strings are the same or not.
 */
const isSameString = (stringOne, stringTwo) => {
    return stringOne.localeCompare(stringTwo) === 0;
}

/**
 * Serialize Form Data
 *
 * Returns one string payload which represents the current data filled into the passed in form element. Note that we
 * are ignoring the Rails `authenticity_token` parameter because it will always change as the form data changes. This
 * would not allow us to compare two different instances of serialized for data inputs updated by the user.
 *
 * Doc: https://developer.mozilla.org/en-US/docs/Web/API/FormData
 *
 * @param  {Element} formElement Instance of the form element we are trying to serialize data from.
 * @return {string}              Serialized form data of all form input key/value pairs as one string.
 */

const serializeFormData = (formElement) => {
    const formData = new FormData(formElement);
    let formDataObject = {};
    for(let [key, value] of formData) {
        if (key !== 'authenticity_token') formDataObject[key] = value;
    }
    return JSON.stringify(formDataObject);
}

/* =====================================================================================================================
    CART FORM ELEMENT METHODS
===================================================================================================================== */

/**
 * Cart Customer PO Item Checkbox Handler
 *
 * Entry point for the "Use this PO for my entire order" checkbox.
 *
 * @return {void} Will call related methods on load and on change for "entire order" checkbox to function.
 */
const cartCustomerPOItemCheckboxHandler = () => {
    const useFullOrderCheckbox = document.querySelector('.js-use-full-customer-po-checkbox');

    if (!useFullOrderCheckbox) return;

    // Run Cart Customer PO input logic on page load.
    cartFullOrderPoCheckboxHandler(useFullOrderCheckbox);

    // Run Cart Customer PO input logic when "entire order" checkbox is changed.
    useFullOrderCheckbox.addEventListener('change', () => {
        cartFullOrderPoCheckboxHandler(useFullOrderCheckbox);
    });
}

/**
 * Cart Full Order Po Checkbox Handler
 *
 * This method will toggle the disable/enable logic for Customer PO inputs depending on the "entire order" checkbox's
 * state. If it is checked, we will disable all Customer PO inputs under each product type group and enable the
 * "Customer PO" input meant for the full order. If it is not checked, we disable the "Customer PO" input meant for the
 * full order and enable all of the other ones in each product type group.
 *
 * @param  {Element} fullOrderCheckbox Required instance of the "entire order" checkbox field.
 * @return {void}                      Updates the disabled/enabled status of Customer PO inputs based off of the
 *                                     "entire order" checkbox.
 */
const cartFullOrderPoCheckboxHandler = (fullOrderCheckbox) => {
    // Disable/enable Customer PO inputs for product type groups based off of "entire order" checkbox field's status.
    const customerPoInputsByProductType = document.querySelectorAll('.js-customer-po-by-type-input');
    if (customerPoInputsByProductType.length !== 0) {
        customerPoInputsByProductType.forEach((customerPoInput) => {
            customerPoInput.disabled = fullOrderCheckbox.checked;
        });
    }

    // Find parent Customer PO form group via "Order Details" section of cart show page.
    const fullCustomerPoFormGroup = fullOrderCheckbox.closest('.js-full-customer-po-form-group');
    if (!fullCustomerPoFormGroup) return;

    // Find full Customer PO input field based off of parent wrapper.
    const fullCustomerPoInput = fullCustomerPoFormGroup.querySelector('.js-full-customer-po-input');
    if(!fullCustomerPoInput) return;

    // Disable/enable full Customer PO input depending on "entire order" checkbox field's status. Require full Customer
    // Po input if checkbox is checked!
    fullCustomerPoInput.disabled = !fullOrderCheckbox.checked;
}

/* =====================================================================================================================
    CART'S CUSTOM FORM VALIDATIONS
===================================================================================================================== */

/**
 * Validate Quantity Inputs Handler
 *
 * Loops through all Quantity Inputs via Cart show page in order to validate the input value. Quantity input value must
 * be greater than zero!
 *
 * @return {void} Attaches necessary event handlers to validate cart item quantity inputs.
 */
const validateQuantityInputsHandler = () => {
    const quantitySelectorInputs = document.querySelectorAll('.js-quantity-selector-input');
    if (quantitySelectorInputs.length === 0) return;

    quantitySelectorInputs.forEach((quantityInput) => {
        validateNumberInputMin(quantityInput)

        quantityInput.addEventListener('change', (event) => {
            const input = event.currentTarget;
            validateNumberInputMin(input)
        });
    });
}

/**
 * Validate Number Input Min
 *
 * This method takes in a number input element and a min number to be validated with. If the number input's value is not
 * more than the defined min number, than a custom bootstrap validator will be set.
 *
 * @param  {HTMLElement} numberInput Number input field to be validated against.
 * @param  {Number}       min_number The min number that the numberInput value can be set to.
 * @return {void}                    Input gets set or unset with custom validity values for bootstrap to catch on the
 *                                   front end.
 */
const validateNumberInputMin = (numberInput, min_number = 0) => {
    if (Number(numberInput.value) === Number(min_number) || Number(numberInput.value) < Number(min_number)) {
        numberInput.setCustomValidity('The quantity input can not be zero!');
    } else {
        numberInput.setCustomValidity('');
    }
}