import validateForms from "../form-validation";
import {selectSortOrder, getSelectedOption, clearFilters} from '../filters-utility';
import { quantitySelectorHandler } from '../components/quantity-selector';

/**
 * Product On Ready (Public/Exported)
 *
 * This is the entry point method for the ProductController based views that gets called in main.js's `ready()` method.
 *
 * @return {void} Loads product related methods.
 */
export const productOnReady = () => {
    validateForms();
    clearProductFilters();
    handleProductSorting();
    handleProductSizeSelector();
    quantitySelectorHandler();
}

/**
 * Clear Product Filters
 *
 * Clears the product filter form and product view of any active filters using filters-utility.js logic. Note, DOM
 * mark-up should be similar to Product or Brand Asset index views.
 *
 * @returns {void}
 */
const clearProductFilters = () => {
    const clearFiltersBtns = document.querySelectorAll('.js-clear-product-filters');
    clearFilters(clearFiltersBtns, '#form-product-filters-collapse', '#form-product-filters');
};

/**
 * Handle Product Sorting
 *
 * Updates the product view with the chosen sort order using filters-utility.js logic. Note, DOM mark-up should be
 * similar to Product or Brand Asset index views.
 *
 * @returns {void} Sets sort order logic using product index view javascript query selectors.
 */
const handleProductSorting = () => {
    selectSortOrder('.js-product-sort-select', '#product-form-order-by-input', '#product-form-order-dir-input', '#form-product-filters');
    selectSortOrder('.js-product-sort-select-collapse', '#product-form-order-by-input-collapse', '#product-form-order-dir-input-collapse', '#form-product-filters-collapse');
};

/**
 * Handle Product Size Selector
 *
 * Navigates the user to the product reflecting the size variant they select. This is used for a product's show page.
 *
 * @returns {void} Redirect user based off of selected wearable product size.
 */
const handleProductSizeSelector = () => {
    const sizeSelector = document.querySelector('.js-size-variant-selector');
    if (!sizeSelector) {
        return;
    }

    sizeSelector.addEventListener('change', (e) => {
        const selectedOption = getSelectedOption(e.target);
        if (!selectedOption) {
            return;
        }

        const variantUrl = selectedOption.getAttribute('data-variant-url');
        if (!variantUrl) {
            return;
        }

        window.location.href = variantUrl;
    });
};