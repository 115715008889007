import {selectSortOrder, clearFilters} from '../filters-utility';

/**
 * Brand Assets On Ready (Public/Exported)
 *
 * This is the entry point method for the BrandAssetsController based views that gets called in main.js's `ready()`
 * method.
 *
 * @returns {void}
 */
export const brandAssetsOnReady = () => {
    clearBrandAssetFilters();
    handleBrandAssetSorting();
}

/**
 * Clear Brand Asset Filters
 *
 * Clears the product filter form and product view of any active filters using filters-utility.js logic.
 *
 * @returns {void}
 */
const clearBrandAssetFilters = () => {
    const clearFiltersBtns = document.querySelectorAll('.js-clear-brand-asset-filters');
    clearFilters(clearFiltersBtns,'#form-brand-asset-filters-collapse', '#form-brand-assets-filters');
};

/**
 * Handle Brand Asset Sorting
 *
 * Updates the brand asset view with the chosen sort order using filters-utility.js logic.
 *
 * @returns {void}
 */
const handleBrandAssetSorting = () => {
    selectSortOrder('.js-brand-asset-sort-select', '#brand-asset-form-order-by-input', '#brand-asset-form-order-dir-input', '#form-brand-assets-filters');
    selectSortOrder('.js-brand-asset-sort-select-collapse', '#brand-asset-form-order-by-input-collapse', '#brand-asset-form-order-dir-input-collapse', '#form-brand-asset-filters-collapse');
};