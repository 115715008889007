/**
 * Quantity Selector Handler
 */
export const quantitySelectorHandler = () => {
    handleQuantityBtns();
    handleQuantityChanges();
    handleNoChargeCheckbox();
}

const handleNoChargeCheckbox = () => {
    const noChargeCheckboxes = document.querySelectorAll('.js-no-charge-checkbox');

    noChargeCheckboxes.forEach((noChargeCheckbox) => {
        // Handle GL Code initialization on load...
        handleGlCodeToggle(noChargeCheckbox);

        // Handle GL Code Toggle on "No Charge" checkbox on change...
        noChargeCheckbox.addEventListener('change', (event) => {
           const targetNoChargeCheckbox = event.currentTarget;
           handleGlCodeToggle(targetNoChargeCheckbox);
        });
    });
}

/**
 * HandleGlCodeToggle
 *
 * @param noChargeCheckboxInput
 */
const handleGlCodeToggle = (noChargeCheckboxInput) => {
    const quantitySelectorWrapper = noChargeCheckboxInput.closest('.gel-quantity-selector');
    const glCodeTextInput = quantitySelectorWrapper.querySelector('.js-gl-code-input');
    const quantityInput = quantitySelectorWrapper.querySelector('.js-quantity-selector-input');
    const glCodeFormGroupWrapper = glCodeTextInput.closest('.input-group');

    if (noChargeCheckboxInput.checked === true) {
        glCodeFormGroupWrapper.classList.remove('d-none');
        glCodeTextInput.disabled = false;
        quantitySelectorWrapper.classList.add('gel-quantity-selector--no-charge');
    } else {
        glCodeFormGroupWrapper.classList.add('d-none');
        glCodeTextInput.disabled = true;
        quantitySelectorWrapper.classList.remove('gel-quantity-selector--no-charge');
    }

    // Force recalculation of price by triggering a change event on quantity input...
    quantityInput.dispatchEvent(new Event('change'));
}

/**
 * Sets triggers on the plus/minus buttons in quantity selectors that increment
 * and decrement the related quantity form input based on it's "step" value
 * @returns {void}
 */
const handleQuantityBtns = () => {
    const decrementBtn = document.querySelectorAll('.js-quantity-selector-decrement');
    const incrementBtn = document.querySelectorAll('.js-quantity-selector-increment');

    if (!decrementBtn || decrementBtn.length === 0) {
        return;
    }

    if (!incrementBtn || incrementBtn.length === 0) {
        return;
    }

    decrementBtn.forEach((btn) => {
        const quantityInput = btn.nextElementSibling;
        const originalStep = quantityInput.getAttribute('data-original-step');

        if (quantityInput.value - originalStep < 1) {
            btn.disabled = true
        } else {
            btn.disabled = false
        }

        btn.addEventListener('click', () => {
            /* Check if we are dealing with odd number case quantity */
            if (Number.isInteger(originalStep)) {
                quantityInput.stepDown();
            } else {
                if (Number.isInteger(+quantityInput.value % +originalStep)) {
                    /* if current case quantity value is equating to a whole number of pallets - no half pallets */
                    quantityInput.step = Math.floor(originalStep);
                    quantityInput.min = Math.floor(originalStep);
                } else {
                    /* if case quantity is a half-pallet value, and therefore has been rounded up */
                    quantityInput.step = Math.ceil(originalStep);
                    quantityInput.min = Math.ceil(originalStep);
                }

                quantityInput.value = +quantityInput.value - +quantityInput.step;

                if (quantityInput.value - originalStep < 1) {
                    btn.disabled = true
                }
            }

                // the `stepDown()` function doesn't seem to trigger a change event
                // on the input, so we need to do it manually
                quantityInput.dispatchEvent(new Event('change'));
        });
    });

    incrementBtn.forEach((btn) => {
        const quantityInput = btn.previousElementSibling;
        const originalStep = quantityInput.getAttribute('data-original-step');
        const decrementBtn = btn.parentNode.querySelector('.js-quantity-selector-decrement')

        btn.addEventListener('click', () => {
            decrementBtn.disabled = false

            if (Number.isInteger(originalStep)) {
                quantityInput.stepUp();
            } else {
                if (Number.isInteger(+quantityInput.value % +originalStep)) {
                    /* if current case quantity value is equating to a whole number of pallets - no half pallets */
                    quantityInput.step = Math.ceil(originalStep);
                    quantityInput.min = Math.ceil(originalStep);
                } else {
                    /* if case quantity is a half-pallet value, and therefore has been rounded up */
                    quantityInput.step = Math.floor(originalStep);
                    quantityInput.min = Math.floor(originalStep);
                }

                quantityInput.value = +quantityInput.value + +quantityInput.step;
            }

            // the `stepUp()` function doesn't seem to trigger a change event
            // on the input, so we need to do it manually
            quantityInput.dispatchEvent(new Event('change'));
        });
    });
};

/**
 * Watches quantity selector input fields for changes and modifies the related
 * price/weight displays based on the new number
 *
 * @returns {void}
 */
const handleQuantityChanges = () => {
    const quantityInputs = document.querySelectorAll('.js-quantity-selector-input');

    if (!quantityInputs || quantityInputs.length === 0) return;

    quantityInputs.forEach((quantityInput) => {
        quantityInput.addEventListener('change', (e) => {
            // TODO: ultimately, it might be nice to send a request to the
            // backend to handle the update calculations, so we don't have to
            // do it in multiple places - rcrichlow - 9/3/2021
            const relatedItemId = e.target.getAttribute('data-related-item');
            if (!relatedItemId) {
                return;
            }

            const quantitySelector = document.querySelector(`#quantity-selector-${relatedItemId}`);
            if (!quantitySelector) {
                return;
            }

            const palletQtyNode = quantitySelector.querySelector('.js-quantity-selector-pallet-qty');
            if (palletQtyNode) {
                recalculatePalletCount(palletQtyNode, quantitySelector);
            }

            const weightNode = quantitySelector.querySelector('.js-quantity-selector-weight');
            if (weightNode) {
                recalculateWeight(weightNode, quantitySelector);
            }

            const basePriceNode = quantitySelector.querySelector('.js-quantity-selector-base-price');
            const priceNode = quantitySelector.querySelector('.js-quantity-selector-total-price');
            const is_no_charge = quantitySelector.classList.contains('gel-quantity-selector--no-charge');
            if (basePriceNode && priceNode) {
                recalculatePrice(basePriceNode, priceNode, Number(e.target.value), is_no_charge);
            }
        });
    });
};

/**
 * Updates the price displayed in a quantity selector.
 *
 * @param {HTMLElement} basePriceNode The HTML element that contains the base price
 * @param {HTMLElement}     priceNode The HTML element that contains the total price
 * @param {Number}           quantity The number of items currently selected
 * @param {Boolean}      is_no_charge Tells us if the current product is set to "No Charge" or not! If "No Charge" is
 *                                    set to true, then our price will be set to $0.00 dollars!
 * @returns {void}
 */
const recalculatePrice = (basePriceNode, priceNode, quantity, is_no_charge = false) => {
    if (!basePriceNode || !priceNode || !quantity) {
        return;
    }

    const price = Number(is_no_charge ? 0 : basePriceNode.textContent.trim());
    const newPrice = price * quantity;
    const calculatedTotalPrice = newPrice.toFixed(2)
    priceNode.textContent = calculatedTotalPrice;

    // Hidden value input to be sent to the Cart session object
    updateHiddenCartItemInput(priceNode, String(calculatedTotalPrice));
};

/**
 * Updates the pallet count in a quantity selector
 *
 * @param {Element} palletQtyNode The element tracking the current pallet count
 * @param {Element} quantitySelector The quantity selector element
 * @returns {void}
 */
const recalculatePalletCount = (palletQtyNode, quantitySelector) => {
    if (!palletQtyNode || !quantitySelector) {
        return;
    }

    const selectorInput = quantitySelector.querySelector('.js-quantity-selector-input');
    if (!selectorInput) {
        return;
    }

    const palletCountRow = quantitySelector.querySelector('.gel-quantity-selector__row[data-cases-per-pallet]');
    if (!palletCountRow) {
        return;
    }

    const casesPerPallet = palletCountRow.getAttribute('data-cases-per-pallet');
    if (!casesPerPallet) {
        return;
    }

    const pallets = Number(selectorInput.value) / Number(casesPerPallet);
    // const calculatedPalletQuantity = Math.ceil(pallets);
    palletQtyNode.textContent = Math.round(pallets * 10) / 10

    updateHiddenCartItemInput(palletQtyNode, String(pallets));
};

/**
 * Updates the weight displayed in a quantity selector
 *
 * @param {Element} weightNode The element tracking the current weight
 * @param {Element} quantitySelector The quantity selector element
 * @returns {void}
 */
const recalculateWeight = (weightNode, quantitySelector) => {
    if (!weightNode || !quantitySelector) {
        return;
    }

    const selectorInput = quantitySelector.querySelector('.js-quantity-selector-input');
    if (!selectorInput) {
        return;
    }

    const weightDataAttribute = 'data-weight-per-case';
    const weightRow = quantitySelector.querySelector(`.gel-quantity-selector__row[${weightDataAttribute}]`);
    if (!weightRow) {
        return;
    }

    const weightPerCase = weightRow.getAttribute(weightDataAttribute);
    if (!weightPerCase) {
        return;
    }

    const palletCasesDataAttribute = 'data-cases-per-pallet';
    const palletWeightDataAttribute = 'data-base-pallet-weight';
    const palletCountRow = quantitySelector.querySelector(`.gel-quantity-selector__row[${palletCasesDataAttribute}]`);
    if (!palletCountRow) {
        return;
    }

    const casesPerPallet = palletCountRow.getAttribute(palletCasesDataAttribute);
    if (!casesPerPallet) {
        return;
    }

    const palletWeight = palletCountRow.getAttribute(palletWeightDataAttribute);

    const qty = Number(selectorInput.value);
    const pallets = qty / Number(casesPerPallet);
    const caseWeightTotal = qty * Number(weightPerCase);
    const totalWeight = caseWeightTotal + (pallets) * Number(palletWeight);
    const calculatedTotalWeight = Math.ceil(totalWeight);
    weightNode.textContent = calculatedTotalWeight;

    updateHiddenCartItemInput(weightNode, String(calculatedTotalWeight));
};

/**
 * Update Hidden Cart Item Input
 *
 * @param {Element} extraDataFieldNode Related element node we are pulling our updated value from. We want to this hidden
 *                                     input in sync so we can save the final payload to the cart session object on form
 *                                     submission.
 * @param {String}     calculatedValue The value of the related extra data field that we are going save in our cart
 *                                     session object on form submit.
 */
const updateHiddenCartItemInput = (extraDataFieldNode, calculatedValue) => {
    if(!extraDataFieldNode) return;

    const hiddenInput = extraDataFieldNode
        .closest('.gel-quantity-selector__row')
        .querySelector('.gel-quantity-selector__hidden-field-value');

    if(!hiddenInput) return;

    hiddenInput.value = calculatedValue;
};
