const handleScrollBarStyles = () => {
    const body = document.querySelector('body');

    if (window.innerWidth > body.clientWidth + 5) {
        body.classList.add('has-scrollbar');
        body.setAttribute('style', '--main-scroll-bar-width: ' + (window.innerWidth - body.clientWidth) + 'px');
    } else {
        body.classList.remove('has-scrollbar');
        body.setAttribute('style', '--main-scroll-bar-width: 0px');
    }
}

export default handleScrollBarStyles;
