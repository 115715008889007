import validateForms from '../../form-validation'
import PasswordValidation from '../../password-validation'

/**
 * Registrations on Ready (Public/Exported)
 *
 * This is the entry point method for the Users::RegistrationsController based views that gets called in main.js's
 * `ready()` method.
 */
export const deviseRegistrationsOnReady = () => {
    validateForms();
    const passwordValidation = new PasswordValidation();
    passwordValidation.initPasswordValidation();
}