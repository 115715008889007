import validateForms from '../../form-validation'
import PasswordValidation from '../../password-validation'

/**
 * Devise Passwords on Ready (Public/Exported)
 *
 * This is the entry point method for the Users::PasswordsController based views that gets called in main.js's `ready()`
 * method.
 */
export const devisePasswordsOnReady = () => {
    validateForms();
    const passwordValidation = new PasswordValidation();
    passwordValidation.initPasswordValidation();
}