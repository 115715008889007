const navbarSearchToggle = () => {
    const searchOpen = document.querySelector('.js-navbar-search-open');
    const searchCancel = document.querySelector('.js-navbar-search-cancel');
    const searchForm = document.querySelector('.js-nav-search-form');
    const activeFormClass = 'navbar__search-form--active';

    const openSearchForm = (e) => {
        e.preventDefault();
        searchForm.classList.add(activeFormClass);

        const searchInput = searchForm.querySelector('.search-form__input');
        if (searchInput) {
            searchInput.focus();
        }
    };

    if(!searchOpen) return;

    searchOpen.addEventListener('click', openSearchForm);

    searchCancel.addEventListener('click', (e) => {
        e.preventDefault();
        searchForm.classList.remove(activeFormClass);
    });

    document.addEventListener('keyup', (e) => {
        if (e.isComposing || e.keyCode === 229) {
            return;
        }

        if (e.key === '/') {
            openSearchForm(e);
        }
    });
}

export default navbarSearchToggle;
