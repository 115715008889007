import validateForms from '../../form-validation'
import PasswordValidation from '../../password-validation'

/**
 * Devise Invitaions on Ready (Public/Exported)
 *
 * This is the entry point method for the Users::InvitationsController based views that gets called in main.js's `ready()`
 * method.
 */
export const deviseInvitationsOnReady = () => {
    validateForms();
    const passwordValidation = new PasswordValidation();
    passwordValidation.initPasswordValidation();
}
