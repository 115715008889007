/**
 * Product Line Items Handler
 */
export const productLineItemsHandler = () => {
    cartItemGroupDataOnLoad();
    cartItemGroupDataOnChange();
    glCodeInputValidationHandler();
    removeCartItemHandler();
}

/* =================================================================
    Product Group Data Funks
================================================================= */
/**
 * Cart Item Group Data Onload (Private)
 *
 * Logic to calculate ALL product group data on initial page load. It will loop through all product type group cards via
 * the Cart index view to kick off the initial calculations for each product group's data fields.
 *
 * @return {void} Calls cartItemGroupFieldHandler method on each present Cart item group in the dom.
 */
const cartItemGroupDataOnLoad = () => {
    const cartItemGroups = document.querySelectorAll('.gel-cart-items-group');
    if (cartItemGroups.length === 0) return;

    cartItemGroups.forEach((productGroup) => {
        cartItemGroupFieldsHandler(productGroup);
    });

    calculateDistributorTotal();
    glCodeTotalsHandler();
}

/**
 * Cart Item Group Data On Change (Private)
 *
 * This method will attach an "on change" event handlers to each product group's quantity input field. If any of the
 * inputs change within a product group, that product group's data will be re-calculated.
 *
 * @return {void} Add on change event listener to each quantity input in order to trigger product group data field
 *                updates.
 */
const cartItemGroupDataOnChange = () => {
    const cartItemGroupQuantityInputs = document.querySelectorAll('.gel-cart-items-group .js-quantity-selector-input');
    if (cartItemGroupQuantityInputs.length === 0) return;

    cartItemGroupQuantityInputs.forEach((quantityInput) => {
        quantityInput.addEventListener('change', (event) => {
            const parentProductGroupElement = event.target.closest('.gel-cart-items-group');
            cartItemGroupFieldsHandler(parentProductGroupElement);
            calculateDistributorTotal();
            glCodeTotalsHandler();
        });
    });
}

/**
 * Cart Item Group Fields Handler (Private)
 *
 * This method is used to differentiate in between custom group field handler methods or the default field handler.
 * Currently, only the beverage product type has custom fields that don't match the default group fields as of
 * 09/24/2021.
 *
 * @param  {Element} cartItemGroup Required cart item group element via Cart index view.
 * @return {void}                  Calls the proper fields handler method depending on group product type.
 */
const cartItemGroupFieldsHandler = (cartItemGroup) => {
    if(!cartItemGroup) return;

    if (cartItemGroup.dataset.groupType === 'beverage') cartItemGroupBeverageFields(cartItemGroup);

    cartItemGroupDefaultFields(cartItemGroup);
}

/**
 * Cart Item Group Default Fields (Private)
 *
 * This method handles the default fields you would expect to see via the Cart Index view's product groups. Currently,
 * this would be all product types other than "beverage" as of 09/22/2021.
 *
 * @param  {Element} cartItemGroup Required cart item group element via Cart index view.
 * @return {void}                  Calls related group field handlers.
 */
const cartItemGroupDefaultFields = (cartItemGroup) => {
    if (!cartItemGroup) return;
    const targetGroupQuantityTotalLabel = cartItemGroup.querySelector('.gel-cart-items-group__group-data-field-total-qty strong');

    setGroupQuantityTotal(cartItemGroup, targetGroupQuantityTotalLabel);
    setGroupPriceTotal(cartItemGroup);
}

/**
 * Set Group Price Total (Private)
 *
 * If Cart Item Group supports a price total, this method will calculate the total and add it to the dom.
 *
 * @param  {Element} cartItemGroup Required Cart item group which supports a "Total Price" field via Cart index view.
 * @return {void}                  Updates the cart index view's "Product Total" with calculated total price.
 */
const setGroupPriceTotal = (cartItemGroup) => {
    if(!cartItemGroup) return;
    const targetGroupTotalPriceField = cartItemGroup.querySelector('.gel-cart-items-group__group-data-field-total-price strong');
    if (!targetGroupTotalPriceField) return;

    const groupTotalPriceFields = cartItemGroup.querySelectorAll('.js-quantity-selector-total-price');
    let groupTotalPricePrefix = '';
    let groupTotalPriceValue = 0.0;

    if (groupTotalPriceFields.length > 0) {
        // Get Currency prefix based off of first total price field...
        groupTotalPricePrefix = groupTotalPriceFields[0]
            .closest('.gel-quantity-selector__row')
            .querySelector('.gel-quantity-selector__field-value-prefix')
            .innerText;

        // Calculate total price of all items in group...
        groupTotalPriceFields.forEach((priceField) => {
            if (isCartItemQueuedToBeDestroyed(priceField)) return;

            groupTotalPriceValue += Number(priceField.innerText)
        });
    }

    const currencyPrefixSpan = createSpanWithCssClass(String(groupTotalPricePrefix), 'js-price-prefix');
    const groupTotalPriceSpan = createSpanWithCssClass(String(groupTotalPriceValue.toFixed(2)), 'js-price-total');

    targetGroupTotalPriceField.innerHTML = '';
    targetGroupTotalPriceField.appendChild(currencyPrefixSpan);
    targetGroupTotalPriceField.appendChild(groupTotalPriceSpan);
}

/**
 * Cart Item Group Beverage Fields (Private)
 *
 * This method handles the custom beverage fields you would expect to see via the Cart index view's product groups.
 *
 * @param  {Element} cartItemGroup Required cart item group element via Cart index view.
 * @return {void}                  Calls related beverage group field handlers.
 */
const cartItemGroupBeverageFields = (cartItemGroup) => {
    if (!cartItemGroup) return;
    const targetGroupQuantityTotalLabel = cartItemGroup.querySelector('.gel-cart-items-group__group-data-field-total-case-qty strong');

    setGroupQuantityTotal(cartItemGroup, targetGroupQuantityTotalLabel);
    setBeverageGroupPalletQuantityTotal(cartItemGroup);
    setBeverageGroupWeightTotal(cartItemGroup);
}

/**
 * Set Group Quantity Total (Private)
 *
 * If Cart Item Group supports a quantity field, this method will calculate the total and add it to the dom.
 *
 * @param  {Element}                 cartItemGroup Required Cart Item Group which supports a js-quantity-selector-input
 *                                                 element via Cart index view.
 * @param  {Element} targetGroupTotalQuantityField Required target element we want to inject our value into.
 * @return {void}                                  Updates the passed in Cart Item Group's "Quantity" field with
 *                                                 calculated total via Cart's index view.
 */
const setGroupQuantityTotal = (cartItemGroup, targetGroupTotalQuantityField) => {
    if (!cartItemGroup) return;
    if (!targetGroupTotalQuantityField) return;

    const quantityInputs = cartItemGroup.querySelectorAll('.js-quantity-selector-input');
    let groupQuantityValue = 0;
    if (quantityInputs.length > 0) {
        quantityInputs.forEach((quantityInput) => {
            if (isCartItemQueuedToBeDestroyed(quantityInput)) return;

            groupQuantityValue += parseInt(quantityInput.value);
        });
    }
    targetGroupTotalQuantityField.innerHTML = groupQuantityValue;
}

/**
 * Set Beverage Group Pallet Quantity Total (Private)
 *
 * Custom method used only for the grouped products of type beverage via Cart index view.
 *
 * @param {Element} cartItemGroup Required cart item group element via Cart index view.
 * @return {void}                 Updates the passed in Cart Item Group's "Pallet Quantity" field with calculated total.
 */
const setBeverageGroupPalletQuantityTotal = (cartItemGroup) => {
    if(!cartItemGroup) return;
    const targetGroupPalletQuantityField = cartItemGroup.querySelector('.gel-cart-items-group__group-data-field-pallet-total strong');
    if (!targetGroupPalletQuantityField) return;

    const palletQuantityFields = cartItemGroup.querySelectorAll('.js-quantity-selector-pallet-qty');
    let groupPalletQuantityValue = 0;
    if (palletQuantityFields.length > 0) {
        palletQuantityFields.forEach((palletQuantityField) => {
            groupPalletQuantityValue += parseFloat(palletQuantityField.innerText);
        });
    }
    targetGroupPalletQuantityField.innerHTML = groupPalletQuantityValue;
}

/**
 * Set Beverage Group Weight Total (Private)
 *
 * Custom method used only for the grouped products of type beverage via Cart index view.
 *
 * @param {Element} cartItemGroup Required cart item group element via Cart index view.
 * @return {void}                 Updates the passed in Cart Item Group's "Weight Total" field with calculated total.
 */
const setBeverageGroupWeightTotal = (cartItemGroup) => {
    if (!cartItemGroup) return;
    const targetGroupWeightTotalField = cartItemGroup.querySelector('.gel-cart-items-group__group-data-field-weight-total strong');
    if (!targetGroupWeightTotalField) return;

    const weightTotalFields = cartItemGroup.querySelectorAll('.js-quantity-selector-weight');
    let groupWeightTotalValue = 0;
    let groupWeightTotalPostfix = '';
    if (weightTotalFields.length > 0) {
        // Get weight's postfix unit based off of first weight field...
        groupWeightTotalPostfix = weightTotalFields[0]
            .closest('.gel-quantity-selector__row')
            .querySelector('.gel-quantity-selector__field-value-postfix')
            .innerText;
        // Calculate total weight of all items in group...
        weightTotalFields.forEach((weightTotalField) => {
            groupWeightTotalValue += parseInt(weightTotalField.innerText);
        });
    }
    targetGroupWeightTotalField.innerHTML = groupWeightTotalValue + ' ' + groupWeightTotalPostfix;
}

/**
 * Calculate Distributor Total (Private)
 *
 * Will calculate the distributor total displayed on the cart show page's order details section.
 *
 * Note: This has to be called after all of the other group price totals have been calculated!
 *
 * @return {void} Element with class .js-distributor-total via Cart's order detail section will be injected with a
 *                calculated distributor total based off of each group data total price labels.
 */
const calculateDistributorTotal = () => {
    const distributorTotalField = document.querySelector('.js-distributor-total');
    distributorTotalField.innerHTML = '';

    if (!distributorTotalField) return;

    const groupDataFieldTotals = document.querySelectorAll('.gel-cart-items-group__group-data-field-total-price strong');
    let distributorTotal = 0.0;
    let currencyPrefix = '$';

    if (groupDataFieldTotals.length > 0) {
        currencyPrefix = groupDataFieldTotals[0].querySelector('.js-price-prefix').innerText;

        groupDataFieldTotals.forEach((groupTotal) => {
            const groupTotalNumber = Number(groupTotal.querySelector('.js-price-total').innerText);
            distributorTotal += groupTotalNumber;
        });
    }

    const distributorCurrencyPrefixSpan = createSpanWithCssClass(String(currencyPrefix), 'js-distributor-total-prefix');
    const distributorTotalSpan = createSpanWithCssClass(String(distributorTotal.toFixed(2)), 'js-distributor-total');

    distributorTotalField.appendChild(distributorCurrencyPrefixSpan);
    distributorTotalField.appendChild(distributorTotalSpan);
}

/**
 * Is Cart Item Queued To Be Destroyed?
 *
 * Given ANY child element of the .gel-cart-items-group__product element... this method will return a true/false value
 * depending on if this product group has been queued up to be "destroyed".
 *
 * @param  {Element} childProductGroupElement Required. Expecting a child element of the .gel-cart-items-group__product
 *                                            DOM element.
 * @return {boolean}                          Will return true if product group has been queued up to be destroyed,
 *                                            false if it has not.
 */
const isCartItemQueuedToBeDestroyed = (childProductGroupElement) => {
    const parentCartItemGroupWrapper = childProductGroupElement.closest('.gel-cart-items-group__product');

    return parentCartItemGroupWrapper.classList.contains('gel-cart-items-group__product--destroy');
}

/* =================================================================
    GL Code Funks
================================================================= */
/**
 * Gl Code Input Validation Handler
 *
 * This method will validate a selected GL Code using the html5 datalist input element. If a user decides to type a
 * random string that is not an available GL Code option, we will set the datalist input element to invalid. Additional
 * logic added to update the input element's value in the dom which the html5 datalist does not do. In addition, we will
 * recalculate all GL Code Totals if any Gl Code input field is changed.
 *
 * @return {void} validate a GL Code input on change and re-calculate all Gl Code totals.
 */
const glCodeInputValidationHandler = () => {
    const glCodeInputs = document.querySelectorAll('.js-gl-code-input');

    if (glCodeInputs.length === 0) return;

    glCodeInputs.forEach((glCodeInput) => {
        const availableGlCodes = getAvailableGlCodeDatalistValues(glCodeInput);

        glCodeInput.addEventListener('change', (event) => {
            const targetInput = event.currentTarget;

            if (availableGlCodes.includes(targetInput.value)) {
                targetInput.setAttribute('value', targetInput.value);
                targetInput.setCustomValidity('');
                targetInput.classList.remove('is-invalid');
            } else {
                targetInput.setAttribute('value', 'Unknown Gl Code');
                targetInput.setCustomValidity('Unknown Gl Code');
                targetInput.classList.add('is-invalid');
            }

            glCodeTotalsHandler();
        });
    });
}

/**
 * Get Available GL Code Datalist Values
 *
 * Get all of the available GL Code options for the passed in Gl Code input element.
 *
 * @param  {HTMLElement}   glCodeInputElement Target GL Code input we want to gather available GL Code options for.
 * @return {Array<String>}   availableGlCodes Array of all of the available selectable GL Code options.
 */
const getAvailableGlCodeDatalistValues = (glCodeInputElement) => {
    let availableGlCodes = []
    if (!glCodeInputElement) return availableGlCodes;

    const glCodeInputWrapperElement = glCodeInputElement.closest('.input-group');
    if (!glCodeInputWrapperElement) return availableGlCodes;

    const glCodeDatalistElement = glCodeInputWrapperElement.querySelector('#available_gl_codes');
    if (!glCodeDatalistElement) return availableGlCodes;

    const availableGlCodeOptions = glCodeDatalistElement.querySelectorAll('option');
    if(availableGlCodeOptions.length === 0) return availableGlCodes;

    availableGlCodeOptions.forEach((glCodeOption) => {
        availableGlCodes.push(glCodeOption.value);
    });

    return availableGlCodes;
}

/**
 * GL Code Totals Handler
 *
 * Entry point method that triggers the calculations of all GL Code Totals in the dom.
 *
 * @return {void} This method will inject all Gl Code totals via the Cart's "Order Details" section above
 *                "Distributor Total".
 */
const glCodeTotalsHandler = () => {
    const glCodeTotalWrapperElement = document.querySelector('.gel-cart-details__gl-code-totals');
    const activeGlCodeInputs = document.querySelectorAll('.js-gl-code-input:not([disabled])');

    showOrHideGlCodeTotalsHandler(activeGlCodeInputs, glCodeTotalWrapperElement);

    const glCodeTotalsData = calculateGlCodeTotals(activeGlCodeInputs);
    const glCodeTotalsRowsMarkup = createGlCodeTotalRowsHTML(glCodeTotalsData);

    removeAllChildElements(glCodeTotalWrapperElement);

    if (glCodeTotalsRowsMarkup.length === 0) return;

    glCodeTotalsRowsMarkup.forEach((glCodeRowElement) => {
        glCodeTotalWrapperElement.appendChild(glCodeRowElement);
    });

    const hrElement = document.createElement('hr');
    glCodeTotalWrapperElement.appendChild(hrElement);
}

/**
 * Show or Hide Gl Code Totals Handler
 *
 * This method will show/hide the Gl Code totals displayed via the Cart show pages' "order details." If there are not
 * active Gl Codes in the dom, we will hide the totals. If there are active Gl Codes in the dom, then we will show them
 * in the "order details" section of the cart show page.
 *
 * @param  {NodeListOf<Element>}         activeGlCodeInputs An array of active Gl Code inputs.
 * @param  {HTMLElement}         glCodeTotalsWrapperElement The dom element which contains all of the calculated Gl Code
 *                                                          totals.
 * @return {void}                                           Show or hide cart view's Gl Code Totals via order detail
 *                                                          section.
 */
const showOrHideGlCodeTotalsHandler = (activeGlCodeInputs, glCodeTotalsWrapperElement) => {
    if (activeGlCodeInputs.length === 0) {
        if (!glCodeTotalsWrapperElement.classList.contains('d-none')) glCodeTotalsWrapperElement.classList.add('d-none');
    } else {
        glCodeTotalsWrapperElement.classList.remove('d-none');
    }
}

/**
 * Calculate Gl Code Totals
 *
 * Calculates the totals for active Gl Code elements in the dom.
 *
 * @param  {NodeListOf<Element>} activeGlCodeInputs An array of active Gl Code inputs.
 * @return {Object}                    glCodeTotals An object containing active GL Code keys with product price as its
 *                                                  value.
 */
const calculateGlCodeTotals = (activeGlCodeInputs) => {
    let glCodeTotals = {}
    if (!activeGlCodeInputs) return glCodeTotals;

    activeGlCodeInputs.forEach((activeGlCodeInput) => {
        const quantitySelectorWrapper = activeGlCodeInput.closest('.gel-quantity-selector');
        const toBeDestroyed = isCartItemQueuedToBeDestroyed(activeGlCodeInput);
        const glCodePrice = getQuantitySelectorGlCodeTotal(quantitySelectorWrapper);

        if(!toBeDestroyed) {
            if (activeGlCodeInput.value in glCodeTotals) {
                glCodeTotals[activeGlCodeInput.value] += glCodePrice;
            } else {
                glCodeTotals[activeGlCodeInput.value] = glCodePrice;
            }
        }
    });

    return glCodeTotals
}

/**
 * Get Quantity Selector Gl Code Total
 *
 * This method will return the product "price" for a GL Code as a Number to be used for calculating GL Code totals. Note
 * that depending on if the product is coop or not, there are different places we need to pull the GL Code "price" from.
 *
 * @param  {HTMLElement} quantitySelectorElement The parent wrapper of the Quantity Selector widget. If a different type
 *                                               of element is passed, or the mark-up has changed, we won't be able to
 *                                               parse the correct product price.
 * @return {Number}                              The number value of the product's original price * quantity.
 */
const getQuantitySelectorGlCodeTotal = (quantitySelectorElement) => {
    const quantityInput = quantitySelectorElement.querySelector('.js-quantity-selector-input');
    const originalPriceElement = quantitySelectorElement.querySelector('.gel-quantity-selector__row--original-price');
    const priceElement = quantitySelectorElement.querySelector('.gel-quantity-selector__row--price');
    const targetPriceElement = priceElement ? priceElement : originalPriceElement;
    const targetPriceValueElement = targetPriceElement.querySelector('.gel-quantity-selector__field-value');

    return Number(targetPriceValueElement.innerText) * Number(quantityInput.value)
}

/**
 * Create Gl Code Totals HTML
 *
 * This method will create the Gl Code Totals mark-up to display on the cart's "order details" section given proper Gl
 * Code totals data via local calculateGlCodeTotals() method.
 *
 * @param  {Object}        glCodeTotalsData An object containing active GL Code keys with product price as its value.
 * @return {Array<HTMLElement>} glCodeTotalsMarkup The mark-up containing Gl Code totals to be injected into the dom.
 */
const createGlCodeTotalRowsHTML = (glCodeTotalsData) => {
    let glCodeRowsMarkup = []
    if (!glCodeTotalsData) return glCodeRowsMarkup;

    Object.keys(glCodeTotalsData).forEach((key) => {
        const glCodeTotalRowHTML = document.createElement('div');
        glCodeTotalRowHTML.setAttribute('class', 'row justify-content-between align-items-center');

        const glCodeHTML = document.createElement('div');
        glCodeHTML.setAttribute('class', 'col-6 form-label mb-0');
        glCodeHTML.innerText = key;

        const glCodePriceHTML = document.createElement('div');
        glCodePriceHTML.setAttribute('class', 'col-auto h5');
        glCodePriceHTML.appendChild(createSpanWithCssClass('$', 'js-price-prefix'));
        glCodePriceHTML.appendChild(createSpanWithCssClass(String(glCodeTotalsData[key].toFixed(2)), 'js-price-total'))

        glCodeTotalRowHTML.appendChild(glCodeHTML);
        glCodeTotalRowHTML.appendChild(glCodePriceHTML);

        glCodeRowsMarkup.push(glCodeTotalRowHTML);
    });

    return glCodeRowsMarkup;
}
/* =================================================================
    Product Line Item Form Input Funks
================================================================= */
/**
 * Remove Cart Item Handler
 *
 * This method is specifically used in the Cart show page's form submission. It will remove the selected Cart Item
 * product from the dom. If this is the last product on the page, it will display the empty cart message in the view.
 * This removes the selected Cart Items from the session Cart object on "Update Cart" because the "removed" Cart Items
 * will not get posted to the update/create controller action. As a result, the "removed" Cart Item will not get saved
 * to the new session Cart object instance. Note that, if you refresh the cart show page without updating... these
 * "removed" cart items will come back because the update/create controller action logic hasn't been run yet.
 *
 * @return {void} Remove selected product, re-calculate group data fields, and possibly display empty cart message if no
 *                more products are present in the view.
 */
const removeCartItemHandler = () => {
    const removeCartItemButtons = document.querySelectorAll('.js-remove-cart-item');

    if(removeCartItemButtons.length === 0) return;

    removeCartItemButtons.forEach((removeCartItemButton) => {
        removeCartItemButton.addEventListener('click', (event) => {
            // Remove single selected product or full product group if selected cart item is the last one in the list
            const currentRemoveCartItemButton = event.currentTarget;
            const currentHiddenDestroyCheckbox = currentRemoveCartItemButton.querySelector('#cart_items_attributes___destroy');
            const currentProductWrapper = currentRemoveCartItemButton.closest('.gel-cart-items-group__product');

            currentProductWrapper.classList.toggle('gel-cart-items-group__product--destroy');
            currentHiddenDestroyCheckbox.value = currentProductWrapper.classList.contains('gel-cart-items-group__product--destroy')
            cartItemGroupDataOnLoad();

            return false;
        });
    });
}
/* =================================================================
    Misc. Helper Funks
================================================================= */
/**
 * Remove All Child Elements
 *
 * This is actually a safe and recommended way to remove child elements from a parent node because this method will also
 * remove event listeners from the removed child nodes. As a result, this will prevent memory leaks.
 *
 * TODO: This should be probably be moved to a javascript utility file to be imported where needed so this method can be shared in-between different javascript files.
 *
 * Doc: https://www.javascripttutorial.net/dom/manipulating/remove-all-child-nodes/
 *
 * @param parentElement
 */
const removeAllChildElements = (parentElement) => {
    while(parentElement.firstChild) {
        parentElement.removeChild(parentElement.firstChild);
    }
}

/**
 * Create Span with CSS Class
 *
 * This method will create and return a new javascript span element based off of passed in params of text and cssClass.
 *
 * @param  {String}             text String to be injected into the created span element.
 * @param  {String}         cssClass String representation of one css class to be set on the new span element.
 * @return {HTMLElement} spanElement New span element created with text and css class attributes.
 */
const createSpanWithCssClass = (text, cssClass) => {
    const spanElement = document.createElement('SPAN');
    spanElement.classList.add(cssClass);
    spanElement.innerText = text;
    return spanElement;
}
