const validateForms = () => {
    const forms = document.querySelectorAll('.needs-validation');

    if (forms) {
        forms.forEach((form) => {
            const formSubmitButtons = form.querySelectorAll('input[type=submit], button[type=submit]');
            formSubmitButtons.forEach((submitButton) => {
                submitButton.addEventListener('click', (event) => {
                    const submitButtonElement = event.currentTarget;
                    const parentFormElement = submitButtonElement.closest('form');
                    if(!parentFormElement.checkValidity()) {
                        event.preventDefault();
                        event.stopPropagation();
                    }

                    parentFormElement.classList.add('was-validated');

                    const invalidInputFields = parentFormElement.querySelectorAll('input.form-control:invalid');
                    invalidInputFields[0].scrollIntoView({behavior: 'smooth', block: 'center'});
                });
            });
        });
    }
};

export default validateForms;
