import Modal from 'bootstrap/js/dist/modal.js';

/**
 * Dynamic Bootstrap Modal Handler (Public/Exported)
 *
 * Entry point for custom/dynamic bootstrap modal logic.
 *
 * @return {void} Calls all related methods to initialize dynamic bootstrap modal logic.
 */
export const dynamicBootstrapModalHandlers = () => {
    dynamicLinkToConfirmationModalHandler();
}

/**
 * Dynamic Confirmation Modal Handler
 *
 * This method will dynamically create a bootstrap modal if an <a></a> tag element that has properly formatted data
 * attributes. For example:
 *
 * <a href="..."
 *    data-modal-confirmation="true"
 *    data-modal-title="My Modal Title"
 *    data-modal-body="My modal body text."
 *    data-modal-submit-label="Submit"
 *    data-modal-cancel-label="Cancel"
 *    ></a>
 *
 * Where the href attribute will be the redirect when the "submit" button is clicked! Cancel will just close and remove
 * the modal from the dom!
 */
const dynamicLinkToConfirmationModalHandler = () => {
    const modalConfirmationLinkTo = document.querySelectorAll('a[data-modal-confirmation=true]');

    if (modalConfirmationLinkTo.length === 0) return;

    modalConfirmationLinkTo.forEach((linkToBtn) => {
        linkToBtn.addEventListener('click', (event) => {
           event.preventDefault();
           event.stopPropagation();

           presentLinkToConfirmationModal(event.currentTarget);
        });
    });
}

/**
 * Present Link To Confirmation Modal
 *
 * This logic builds and presents the confirmation modal based off of the set data attributes on the original a tag link
 * clicked. It will also remove the injected bootstrap modal from the dom if closed.
 *
 * @param  {HTMLElement} linkToBtn The initial a tag that was configured to present a modal.
 * @return {void}                  Modal is injected and presented in the dom. If user closes the modal, it will be
 *                                 removed from the dom.
 */
const presentLinkToConfirmationModal = (linkToBtn) => {
    // Gather required link to params... to pass into modal submit link button.
    const linkToHref = linkToBtn.href;

    // Build modal based off of bootstrap specifications...
    const siteBodyElement = document.querySelector('body');
    const confirmationModal = buildModalWrapperElement();
    const modalDialog = buildModalDialogElement();
    const modalContent = buildModalContentElement();
    const modalBody = buildModalBodyElement(linkToBtn.dataset);
    const modalFooter = buildModalFooterElement(linkToBtn.dataset, linkToHref);
    modalContent.appendChild(modalBody);
    modalContent.appendChild(modalFooter);
    modalDialog.appendChild(modalContent);
    confirmationModal.appendChild(modalDialog);
    siteBodyElement.appendChild(confirmationModal);

    // Show modal as soon as it has been built.
    let confirmationModalBootstrapInstance = new Modal(confirmationModal, {});
    confirmationModalBootstrapInstance.show();

    // Remove modal from dom once closed...
    confirmationModal.addEventListener('hidden.bs.modal', (event) => {
        event.currentTarget.remove();
    });
}

/**
 * Build Modal Wrapper Element
 *
 * Create the modal's outer most html element with bootstrap related attributes needed to support bootstrap modal logic.
 *
 * Doc: https://getbootstrap.com/docs/5.0/components/modal/#examples
 *
 * @return {HTMLDivElement} modalWrapper The outer most div element for the bootstrap modal mark-up based off of
 *                                       bootstrap documentation.
 */
const buildModalWrapperElement = () => {
    const modalWrapper = document.createElement('div');
    modalWrapper.classList.add('modal', 'fade');
    modalWrapper.setAttribute('id','link-to-confirmation-modal');
    modalWrapper.setAttribute('aria-labelledby', 'linkToConfirmationModal');
    modalWrapper.setAttribute('aria-hidden', 'true');
    modalWrapper.setAttribute('tabindex', '-1');
    return modalWrapper;
}

/**
 * Build Modal Dialog Element
 *
 * Create the modal's dialog html element with bootstrap related attributes needed to support bootstrap modal logic.
 *
 * Doc: https://getbootstrap.com/docs/5.0/components/modal/#examples
 *
 * @return {HTMLDivElement} modalDialog The dialog div element for the bootstrap modal mark-up based off of bootstrap
 *                                      documentation.
 */
const buildModalDialogElement = () => {
    const modalDialog = document.createElement('div');
    modalDialog.classList.add('modal-dialog');
    return modalDialog;
}

/**
 * Build Modal Content Element
 *
 * Create the modal's content html element with bootstrap related attributes needed to support bootstrap modal logic.
 *
 * Doc: https://getbootstrap.com/docs/5.0/components/modal/#examples
 *
 * @return {HTMLDivElement} modalContent The content div element for the bootstrap modal mark-up based off of bootstrap
 *                                       documentation.
 */
const buildModalContentElement = () => {
    const modalContent = document.createElement('div');
    modalContent.classList.add('modal-content');
    return modalContent;
}

/**
 * Build Modal Body Element
 *
 * Create the modal's body html element with bootstrap related attributes needed to support bootstrap modal logic.
 *
 * Doc: https://getbootstrap.com/docs/5.0/components/modal/#examples
 * @param  {DOMStringMap}  linkToData The original a tag's data attributes used to parse data from.
 * @return {HTMLDivElement} modalBody The body div element for the bootstrap modal element based off of bootstrap
 *                                    documentation.
 */
const buildModalBodyElement = (linkToData) => {
    const modalBody = document.createElement('div');
    modalBody.classList.add('modal-body', 'pt-4', 'px-4');

    if (linkToData['modalTitle']) modalBody.appendChild(buildModalTitleElement(linkToData['modalTitle']));
    if (linkToData['modalBody']) modalBody.appendChild(buildModalBodyTextElement(linkToData['modalBody']));

    return modalBody;
}

/**
 * Build Modal Title Element
 *
 * Create the modal's body's title element with default title if none was provided.
 *
 * @param  {String}         titleText Optional title text input to display as the modal's title.
 * @return {HTMLElement} titleElement The body's title element for the bootstrap modal mark-up.
 */
const buildModalTitleElement = (titleText = '') => {
    if (titleText === '') titleText = 'Are you sure you want to proceed? ';
    const titleElement = document.createElement('strong');
    titleElement.classList.add('mb-3', 'd-block');
    titleElement.innerText = titleText;
    return titleElement;
}

/**
 * Build Modal Body Text Element
 *
 * Create the modal's body's text element with default message if none was provided.
 *
 * @param  {String}             bodyText Optional body text input to display as the modal's body message.
 * @return {HTMLElement} bodyTextElement The body's text element for the bootstrap modal mark-up.
 */
const buildModalBodyTextElement = (bodyText = '') => {
    if (bodyText === '') bodyText  = 'Please confirm if you would like to submit or cancel.';
    const bodyTextElement = document.createElement('p');
    bodyTextElement.innerText = bodyText;
    return bodyTextElement;
}

/**
 * Build Modal Footer Element
 *
 * Create the modal's footer html element with bootstrap related attributes needed to support bootstrap modal logic.
 * This element will contain the modal's buttons required to respond to the confirmation message.
 *
 * Doc: https://getbootstrap.com/docs/5.0/components/modal/#examples
 *
 * @param  {DOMStringMap}           linkToData The original a tag's data attributes used to parse data from.
 * @param  {String}               submitToLink The original a tag's href link data to transfer to the modal's submit
 *                                             button.
 * @return {HTMLDivElement} modalFooterElement The footer div element for the bootstrap modal mark-up based off of
 *                                             bootstrap documentation.
 */
const buildModalFooterElement = (linkToData, submitToLink) => {
    const modalFooterElement = document.createElement('div');
    modalFooterElement.classList.add('modal-footer', 'border-0', 'px-4', 'pb-4');

    if(linkToData['modalSubmitLabel']) modalFooterElement.appendChild(buildModalSubmitButtonElement(linkToData['modalSubmitLabel'], submitToLink, linkToData['method']));
    if(linkToData['modalCancelLabel']) modalFooterElement.appendChild(buildModalCancelButtonElement(linkToData['modalCancelLabel']));

    return modalFooterElement;
}

/**
 * Build Modal Submit Button Element
 *
 * Create the modal's footer's submit button html element with the original a tag's link_to attributes. We want this
 * button to complete the original a tag's href destination that we prevented from executing in
 * dynamicLinkToConfirmationModalHandler method's event.preventDefault() and event.stopPropagation() calls.
 *
 * @param  {String}           submitButtonText Optional submit button's inner text. We will set default text to
 *                                             "Submit" if no value was provided.
 * @param  {String}               submitToLink The original a tag's href link data to transfer to the modal's submit
 *                                             button. We will set it to "#" if nothing was provided.
 * @param  {String}             submitToMethod Optional data-method parameter which is used in rail's link_to tag helper
 *                                             to post param data to different controller actions.
 * @return {HTMLElement}   submitButtonElement The footer's submit button element for the bootstrap modal mark-up based
 *                                             off of the original a tag's data attributes.
 */
const buildModalSubmitButtonElement = (submitButtonText = 'Submit', submitToLink = '#', submitToMethod = '') => {
    const submitButtonElement = document.createElement('a');
    submitButtonElement.classList.add('btn', 'btn-primary', 'btn-sm', 'w-auto');
    submitButtonElement.innerText = submitButtonText;
    submitButtonElement.setAttribute('href', submitToLink);
    submitButtonElement.setAttribute('data-disable-with', 'Please wait...');
    if (submitToMethod !== '') submitButtonElement.setAttribute('data-method', submitToMethod);

    return submitButtonElement;
}

/**
 * Build Modal Cancel Button Element
 *
 * Create the modal's footer's cancel button element with bootstrap related attributes needed to support bootstrap modal
 * logic. Attributes include requirements needed to automatically dismiss the modal on cancel.
 *
 * Doc: https://getbootstrap.com/docs/5.0/components/modal/#examples
 *
 * @param  {String}            cancelButtonText Optional text to use for the Modal's cancel button. We will set the
 *                                              button's text to "Cancel" by default.
 * @return {HTMLElement}    cancelButtonElement The footer div element's cancel button for the bootstrap modal mark-up
 *                                              based off of bootstrap documentation.
 */
const buildModalCancelButtonElement = (cancelButtonText = 'Cancel') => {
    const cancelButtonElement = document.createElement('button');

    cancelButtonElement.setAttribute('type', 'button');
    cancelButtonElement.setAttribute('class','btn btn-outline-primary btn-sm w-auto');
    cancelButtonElement.setAttribute('data-bs-dismiss','modal');

    cancelButtonElement.innerText = cancelButtonText;

    return cancelButtonElement;
}