/**
 * Clear Filters
 *
 * Clears the index filter form and index view of any active filters IF the mark-up is architected similar to the
 * Product or Brand Assets index views.
 *
 * @param  {NodeListOf<Element>} clearFilterButtons Array of possible clear filter buttons in the dom. Expect a list to
 *                                                  support desktop and mobile filters logic!
 * @param  {String}                formFilterMobile CSS element target id or class for the mobile form filters present
 *                                                  on index view.
 * @param  {String}               formFilterDesktop CSS element target id or class for the desktop form filters present
 *                                                  on index view.
 * @return {void}                                   Adds event listeners to clear filter buttons on click.
 */
export const clearFilters = (clearFilterButtons, formFilterMobile, formFilterDesktop) => {
    if (clearFilterButtons.length === 0) return;

    clearFilterButtons.forEach((clearFiltersButton) => {
        clearFiltersButton.addEventListener('click', () => {
            clearForm(formFilterMobile);
            clearForm(formFilterDesktop, true);
        });
    });
};

/**
 * Clear Form
 *
 * Given a form query selector path, this method will clear all filter options and re-submit the filter payload so
 * queried data gets reset. This logic is used via Product and Brand Assets index views "Clear Filters" button action!
 *
 * @param formSelector {String}  formSelector Target query selector string for form element.
 * @param doSubmitForm {Boolean} doSubmitForm This boolean param will decided whether or not we want to force submit a
 *                                            form cleared of all inputs when this method is called.
 * @return {void}                             Clear all form inputs and submit empty form payload if asserted.
 */
const clearForm = (formSelector, doSubmitForm = false) => {
    const form = document.querySelector(formSelector);
    if (!form) {
        return;
    }

    const formElements = form.elements;
    if (!formElements || !formElements.length) {
        return;
    }

    for (let i = 0; i < formElements.length; i++) {
        if (formElements[i].checked) {
            formElements[i].checked = false;
        }
    }

    if (doSubmitForm) {
        form.submit();
    }
};

/**
 * Select Sort Order
 *
 * Generalized "Sort Filter" logic for the Product and Brand Assets index views. If new views are built similarly to
 * these, this logic should work the same way given matching query selectors!
 *
 * @param  {String}               selectorClass Javascript query selector for the "Order" select input.
 * @param  {String}  orderByInputSelectorString Javascript query selector for the hidden "Order By" input via filters
 *                                              form which will submit the "Order By" payload to the controller on form
 *                                              filters submit. By default, this will sort order by :name column!
 * @param  {String} orderDirInputSelectorString Javascript query selector for the hidden "Order Direction" input via
 *                                              filters form which will the order direction payload to the controller on
 *                                              form submit. Depending on selected option, this will be :asc or :desc.
 * @param  {String}          formSelectorString Javascript query selector for the filters form that should be present on
 *                                              index view. It is the wrapper for all form filter inputs!
 * @return {void}                               Update the form filters using new "Order" options and submit filters
 *                                              form to update index view with new filters query.
 */
export const selectSortOrder = (selectorClass, orderByInputSelectorString, orderDirInputSelectorString, formSelectorString) => {
    const sortSelect = document.querySelector(selectorClass);
    if (!sortSelect) {
        return;
    }

    sortSelect.addEventListener('change', (e) => {
        const selectedOption = getSelectedOption(e.target);
        const orderByInput = document.querySelector(orderByInputSelectorString);
        if (!orderByInput) {
            return;
        }

        orderByInput.value = selectedOption.getAttribute('data-order-by');

        const orderDirInput = document.querySelector(orderDirInputSelectorString);
        if (!orderDirInput) {
            return;
        }

        orderDirInput.value = selectedOption.getAttribute('data-order-dir');

        const form = document.querySelector(formSelectorString);
        if (!form) {
            return;
        }

        form.submit();
    });
};

/**
 * Get Selected Option
 *
 * Checks the provided select element for the currently selected option.
 *
 * TODO: This method could probably live in a FormElementUtilities.js file to be used in other javascript files.
 *
 * @param  {HTMLSelectElement}            selectField The input check field to validate selected option for.
 * @returns {HTMLOptionElement|undefined}             The option element that is active in the target select list.
 */
export const getSelectedOption = (selectField) => {
    if (!selectField || !selectField.options || selectField.options.length === 0) {
        return;
    }

    const selectedIndex = selectField.options.selectedIndex;
    return selectField.options[selectedIndex];
};