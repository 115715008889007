const zxcvbn = require('zxcvbn');

class PasswordValidation {
    constructor() {
        this.minimumScore = 2;

        this.strength = {
            0: "Not strong enough.",
            1: "Not strong enough.",
            2: "Strong enough, but could be better.",
            3: "Strong.",
            4: "Very strong."
        }

        this.password = document.getElementById('user_password');
        this.passwordFeedback = document.getElementById('invalid-feedback-password');
        this.passwordShowButton = document.getElementById('show_password');
        this.meter = document.getElementById('strength-meter-fill');
        this.strengthText = document.getElementById('password-strength-text');

        this.passwordButtonTextHide = "Hide";
        this.passwordButtonTextShow = "Show";

        this.inputValue = ""
        this.result = {}
    }

    toggleShowPassword() {
        if (this.password.type === 'password') {
            this.password.type = "text";
            this.passwordShowButton.innerText = this.passwordButtonTextHide;
        } else {
            this.password.type = "password"
            this.passwordShowButton.innerText = this.passwordButtonTextShow;
        }
    }

    generateCustomValidation() {
        // TODO: these messages should live somewhere global.
        // custom password validation
        if (this.inputValue === "") {
            this.password.setCustomValidity("Missing password");
            this.passwordFeedback.innerHTML = "Please enter your password.";
        } else if (this.result.score < this.minimumScore) {
            this.password.setCustomValidity("Password is not strong enough");
            this.passwordFeedback.innerHTML = "Please create a stronger password.";
        } else {
            this.password.setCustomValidity("");
            this.passwordFeedback.innerHTML = "";
        }
    }

    updateMeter() {
        this.meter.setAttribute('data-strength', this.result.score);
    }

    resetStrengthText() {
        this.strengthText.innerHTML = "Password Strength:"
    }

    // Update the text indicator
    updateTextIndicator() {
        if(this.inputValue !== "") {
            const strengthText = this.strength[this.result.score] + '. ';
            const feedbackWarning = this.result.feedback.warning + ' ';
            // const feedbackSuggestions = this.result.feedback.suggestions;
            const openingTag = "<span class='feedback'>";
            const closingTag = "</span>";

            this.strengthText.innerHTML = strengthText + openingTag + feedbackWarning + closingTag;
        } else {
            this.resetStrengthText()
        }
    }

    validatePassword(e) {
        this.inputValue = e.target.value;
        this.result = zxcvbn(this.inputValue);

        this.generateCustomValidation();
        this.updateMeter();
        this.updateTextIndicator();
    }

    initPasswordValidation() {
        if (this.password) {
            this.password.addEventListener('input', this.validatePassword.bind(this))

            if (this.passwordShowButton) {
                this.passwordShowButton.addEventListener('click', this.toggleShowPassword.bind(this))
            }
        }
    }
}

export default PasswordValidation;
