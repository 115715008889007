import validateForms from '../form-validation';

/**
 * Request Access On Ready (Public/Exported)
 *
 * This is the entry point method for the RequestAccessController based views that gets called in main.js's `ready()`
 * method.
 *
 * @return {void} Loads request access form related methods.
 */
export const requestAccessOnReady = () => {
    validateForms();
}