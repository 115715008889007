/**
 * Set Shipping Address On Ready (Public/Exported)
 *
 * This is the entry point method for the SetShippingAddressesController based views that gets called in main.js's `ready()` method.
 */
export const setShippingAddressOnReady = () => {
    dropdownItemActiveToggleHandler();
    validateActiveOptionBeforeSubmitHandler();
}

/**
 * Dropdown Item Active Toggle Handler
 *
 * This method will set and remove an `active` class from the parent `dropdown-item` element depending on if a
 * `shipping_address` radio button was activated or not. This is so we can style the active state of the `dropdown-item`
 * element in scss.
 *
 * @return {void} This method toggles an `active` class on `dropdown-item` elements.
 */
const dropdownItemActiveToggleHandler = () => {
    const shippingAddressRadioOptions = document.getElementsByName('shipping_address');

    shippingAddressRadioOptions.forEach((shippingAddressOption) => {
        shippingAddressOption.addEventListener('change', (event) => {
            const activeDropdownItem = document.querySelector('.dropdown-item.active');
            if(activeDropdownItem) activeDropdownItem.classList.remove('active');

            const selectedDropdownItem = event.currentTarget.closest('.dropdown-item');
            selectedDropdownItem.classList.add('active');
        });
    });
}

/**
 * Validate Active Option Before Submit Handler
 *
 * This method will enable the "Select a Shipping Address" form once a user has selected a shipping address. Page is
 * loaded with the submit disabled so user's are forced to select an address.
 *
 * @return {void} Enables the submit button once a user selects an address.
 */
const validateActiveOptionBeforeSubmitHandler = () => {
    const formSubmitButton = document.querySelector('#submit-set-shipping-address');

    if(!formSubmitButton) return;

    const dropdownItemsRadioInputs = document.getElementsByName('shipping_address');

    if(dropdownItemsRadioInputs.length === 0) return;

    dropdownItemsRadioInputs.forEach((radioInput) => {
        radioInput.addEventListener('change', (event) => {
            let hasSetValue = false;
            dropdownItemsRadioInputs.forEach((radioInput) => {
                if (radioInput.checked) {
                    hasSetValue = true;
                }
            });

            formSubmitButton.disabled = !hasSetValue;
        });
    });
}

