/**
 * Shipping Address Search Handler (Public/Exported)
 *
 * Root method called to initialize the Shipping Address search logic.
 *
 * @return {void} Calls all related methods to initialize the search input logic.
 */
export const shippingAddressSearchHandler = (shippingAddressSearchInput) => {
    if(!shippingAddressSearchInput) return;

    searchAddressInputOnKeyUp(shippingAddressSearchInput);
    searchAddressInputOnCloseBtnOrEnterKey(shippingAddressSearchInput);
}

/**
 * Search Address Input On Key Up
 *
 * This method adds an event listener to the passed in "search input" which will trigger any time a user types a new
 * character or removes a character from the search input field. Within this event listener, we trigger the search logic
 * which hides irrelevant bootstrap `dropdown-items` within the current `dropdown-menu` list.
 *
 * @param  {HTMLElement} searchInput Javascript instance of the search input we are capturing data from.
 * @return {void}                    Will trigger the true comparison logic which show/hides `dropdown-items` based off
 *                                   of search input.
 */
const searchAddressInputOnKeyUp = (searchInput) => {
    searchInput.addEventListener('keyup', (event) => {
        const searchInput = event.currentTarget
        searchInputHandler(searchInput);
    });
}

/**
 * Search Address Input On Close Button Or Enter Key
 *
 * This adds a browser based event listener to the passed in "search input" which triggers whenever a user submits a
 * search by pressing the enter key on their keyboard or by clicking on the browser's clear search (x) button.
 *
 * @param  {HTMLElement} searchInput Javascript instance of the search input we are capturing data from.
 * @return {void}                    Will trigger the true comparison logic which show/hides `dropdown-items` based off
 *                                   of search input.
 */
const searchAddressInputOnCloseBtnOrEnterKey = (searchInput) => {
    searchInput.addEventListener('search', (event) => {
        const searchInput = event.currentTarget;
        searchInputHandler(searchInput);
    });
}

/**
 * Search Input Handler
 *
 * True search logic which will compare the value the user typed into the search input field against all of the text
 * content inside of the `dropdown-item` data. If a match is found in any of the `dropdown-item`s within the
 * `dropdown-menu`'s list; the item will be displayed. Otherwise, the item will be hidden from the view. If a blank
 * search value is submitted, the full list will display all values again (like a search reset).
 *
 * @param  {HTMLElement} searchInput Javascript instance of the search input we are capturing data from.
 * @return {void}                    Show/hide `dropdown-items` based off of search input.
 */
const searchInputHandler = (searchInput) => {
    const searchInputValue = searchInput.value.toLowerCase();
    const shippingAddressContainer = searchInput.closest('.js-set-shipping-address-container');
    const shippingAddressOptions = shippingAddressContainer.querySelectorAll('.js-shipping-label-address');

    shippingAddressOptions.forEach((shippingAddressOption) => {
        const shippingDropdownItem = shippingAddressOption.closest('.dropdown-item');
        const shippingAddressText = shippingAddressOption.textContent.toLowerCase();

        if (searchInputValue === '') {
            showElementWithBootstrapClasses(shippingDropdownItem);
            return;
        }

        (shippingAddressText.search(searchInputValue) >= 0) ? showElementWithBootstrapClasses(shippingDropdownItem) : hideElementWithBootstrapClasses(shippingDropdownItem);
    });
}

/**
 * Show Element With Bootstrap Classes
 *
 * Will show passed in html element using bootstrap css classes.
 *
 * @param  {HTMLElement} element Passed in html element we want to show.
 * @return {void}                Shows passed in element using bootstrap css classes.
 */
const showElementWithBootstrapClasses = (element) => {
    element.classList.remove('d-none');
    element.classList.add('d-block');
}

/**
 * Hide Element With Bootstrap Classes
 *
 * Will hide passed in html element using bootstrap css classes.
 *
 * @param  {HTMLElement} element Passed in html element we want to hide.
 * @return {void}                Hides passed in element using bootstrap css classes.
 */
const hideElementWithBootstrapClasses = (element) => {
    element.classList.remove('d-block');
    element.classList.add('d-none');
}