/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/packs and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//

import {deviseInvitationsOnReady} from "../scripts/layouts/devise/invitations";

const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// import 'core-js/stable'
// import 'regenerator-runtime/runtime'

// Even though these look as though they're not used, they actually are required.
import Dropdown from 'bootstrap/js/dist/dropdown';
import Collapse from 'bootstrap/js/dist/collapse';
import Alert from 'bootstrap/js/dist/alert';

import '../stylesheets/main.scss';

import handleScrollBarStyles from '../scripts/scrollbar.js';
import navbarSearchToggle from '../scripts/navbar-search.js';

// *** IMPORTANT! ***
// Do not remove this line. For some reason, this is required for Devise's
// logout functionality. Without this, calling destroy_user_session_path
// will default to a GET despite telling it to use DESTROY, and will break.
// TODO: is this still needed? it seems to be throwing errors on several pages. - rcrichlow - 08/05/21
require("@rails/ujs").start()

const dismissCollapseMenus = () => {
    window.addEventListener('click', (e) => {
        const target = e.target;

        if (!target.hasAttribute('aria-expanded') || !target.getAttribute('aria-expanded')) {
            const productSubmenu = document.getElementById('productMenuCollapse');
            const productSubmenuCollapse = Collapse.getInstance(productSubmenu);
            if (productSubmenuCollapse) {
                productSubmenuCollapse.hide();
            }
        }
    });
}

/**
 * Layout Imports
 *
 * This block imports any custom layout based views.
 */
import { brandAssetsOnReady } from "../scripts/layouts/brand_assets";
import { cartOnReady } from '../scripts/layouts/cart';
import { contactUsOnReady} from "../scripts/layouts/contact_us";
import { ordersOnReady } from '../scripts/layouts/orders';
import { devisePasswordsOnReady } from '../scripts/layouts/devise/passwords';
import { deviseRegistrationsOnReady } from '../scripts/layouts/devise/registrations';
import { dynamicBootstrapModalHandlers } from '../scripts/components/bootstrap-modal-handler';
import { productOnReady } from '../scripts/layouts/product';
import { requestAccessOnReady } from '../scripts/layouts/request_access';
import { setShippingAddressOnReady } from '../scripts/layouts/set_shipping_addresses';
import { shippingAddressRequestsOnReady } from '../scripts/layouts/shipping_address_requests';
import { shippingAddressSearchHandler } from '../scripts/components/shipping-address-search-handler';

const ready = () => {
    const bodyNode = document.getElementsByTagName('body')[0];

    handleScrollBarStyles();
    dismissCollapseMenus();
    navbarSearchToggle();
    dynamicBootstrapModalHandlers();

    if (bodyNode.classList.contains('brand_assets')) brandAssetsOnReady();

    if (bodyNode.classList.contains('carts')) cartOnReady();

    if (bodyNode.classList.contains('contact')) contactUsOnReady();

    if (bodyNode.classList.contains('orders')) ordersOnReady();

    const productControllerClasses = ['products', 'product_beverages', 'product_pos', 'product_wearables', 'product_promos']
    if (productControllerClasses.some(className => bodyNode.classList.contains(className))) productOnReady();

    if (bodyNode.classList.contains('request_access')) requestAccessOnReady();

    if (bodyNode.classList.contains('set_shipping_addresses')) setShippingAddressOnReady();

    if (bodyNode.classList.contains('shipping_address_requests')) shippingAddressRequestsOnReady();

    const shippingAddressSearchInput = document.querySelector('.js-search-shipping-addresses');
    shippingAddressSearchHandler(shippingAddressSearchInput);

    // Devise Views
    if (bodyNode.classList.contains('invitations')) deviseInvitationsOnReady();
    if (bodyNode.classList.contains('passwords')) devisePasswordsOnReady();
    if (bodyNode.classList.contains('registrations')) deviseRegistrationsOnReady();
}

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', ready);
} else {
    ready();
}
