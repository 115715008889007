import validateForms from '../form-validation'

/**
 * Contact Us On Ready (Public/Exported)
 *
 * This is the entry point method for the ContactController based views that gets called in main.js's `ready()`
 * method.
 */
export const contactUsOnReady = () => {
    validateForms();
}