import { quantitySelectorHandler } from '../components/quantity-selector';
import validateForms from "../form-validation";
import { productLineItemsHandler } from "../components/product-line-items-handler"
/**
 * Cart Handler (Public/Exported)
 *
 * This is the entry point method for the CartController based views that gets called in main.js's `ready()` method.
 */
export const ordersOnReady = () => {
    productLineItemsHandler();
    quantitySelectorHandler();
    validateForms();
}